import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
import Menu from "../components/responsive-menu/menu.module.scss";
import Header from "../components/responsive-menu/header";
import Image from "../components/responsive-menu/image";
import Footer from "../components/footer";
import Metatags from "../components/metatags";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Metatags title="Milligram feed | May 9 | CSS Grid a day" description="An example of using CSS grid every day, from May 1–31st, 2019." thumbnail="https://cssgrid31.brett.cool/opengraph/may-9.png" url="https://cssgrid31.brett.cool" pathname="/may-9" mdxType="Metatags" />
    <div className={Menu.container}>
  <Header active={'home'} mdxType="Header" />
  <div className={Menu.posts}>
    <Image account="brettjay" filename="P5023345" caption="A pretty good caff" mdxType="Image" />
    <Image account="brettjay" filename="P5023353" caption="Getting them beans" mdxType="Image" />
    <Image account="brettjay" filename="P5023364" caption="Views from above" mdxType="Image" />
    <Image account="brettjay" filename="P5023384" caption="Nearly ready ☕️👌" mdxType="Image" />
  </div>
    </div>
    <Footer date={9} prev={true} next={true} mdxType="Footer">
      <p>{`Originally I had planned to just make a responsive menu example, going from a bottom tab bar on mobile, to a top navigation combined with the header for tablets and desktop.`}</p>
      <p>{`Then I thought, `}<em parentName="p">{`“Oh, I probably need to add a bit of content for scrolling, just as an example...”`}</em></p>
      <p>{`Before I knew it, I’d created a bit of an Instagram-like feed, figured I’d make it a follow-up to Milligram.`}</p>
      <p>{`So... Introducing Milligram—the social network for posting coffee—that apparently, only I use.`}</p>
    </Footer>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      